import type { StructuredToolCall } from '@kanbu/schema';
import type { RagResultToolDef } from '@kanbu/schema/contracts';
import { ContentType } from '@kanbu/schema/enums';
import { ChevronRight, File, Globe } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PartialDeep } from 'type-fest';

import { MessageContainer } from '../components/MessageContainer';
import { MessageMarkdown } from '../components/MessageMarkdown';

export type RagResultToolProps = {
  toolCall: StructuredToolCall<PartialDeep<RagResultToolDef>>;
};

/**
 * This is for testing, but I will leave it here, until there are some
 * tools for inspiration.
 */
export const RagResultTool = memo(function RagResultTool({
  toolCall,
}: RagResultToolProps) {
  const { t } = useTranslation();
  const { content, sources = [] } = toolCall.args;
  const filteredSources = sources.filter(source => source.sourceUrl);

  return (
    <>
      <MessageContainer className='flex-col gap-2'>
        <MessageMarkdown>{content}</MessageMarkdown>
      </MessageContainer>
      {sources.length > 0 && (
        <MessageContainer className='flex-col gap-2 rounded-2xl rounded-tl-none'>
          <h4 className='text-sm font-bold'>{t('tools.sources')}</h4>
          <ul className='flex flex-col gap-1'>
            {filteredSources?.map((source, index) => (
              <li
                className='grid grid-cols-[1fr_auto] items-center gap-1'
                key={index}
              >
                <a
                  className='inline-flex items-baseline break-all hover:underline'
                  href={source?.sourceUrl}
                  target='_blank'
                  rel='noreferrer'
                >
                  {source?.type === ContentType.Document ? (
                    <File className='relative top-0.5 mr-1 size-4 shrink-0' />
                  ) : source?.type === ContentType.Website ? (
                    <Globe className='relative top-0.5 mr-1 size-4 shrink-0' />
                  ) : null}
                  <span>{source?.fileName ?? source?.sourceUrl}</span>
                </a>
                <ChevronRight className='size-4 shrink-0' />
              </li>
            ))}
          </ul>
        </MessageContainer>
      )}
    </>
  );
});
