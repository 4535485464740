import { ChatRole } from '@kanbu/schema/enums';
import { cn } from '@utima/ui';
import {
  createContext,
  memo,
  useMemo,
  type ComponentPropsWithoutRef,
} from 'react';

export type MessageLayoutProps = Omit<
  ComponentPropsWithoutRef<'div'>,
  'role'
> & {
  role: ChatRole;
};

export type MessageLayoutContextValue = {
  role: ChatRole;
};

export const MessageLayoutContext = createContext<MessageLayoutContextValue>({
  role: ChatRole.Assistant,
});

export const MessageLayout = memo(function MessageLayout({
  className,
  children,
  role,
  ...restProps
}: MessageLayoutProps) {
  const contextValue = useMemo<MessageLayoutContextValue>(
    () => ({ role }),
    [role],
  );

  return (
    <MessageLayoutContext.Provider value={contextValue}>
      <div
        className={cn('relative flex flex-col', className, {
          ['items-start']: role === ChatRole.Assistant,
          ['items-end']: role === ChatRole.User,
        })}
        {...restProps}
      >
        {children}
      </div>
    </MessageLayoutContext.Provider>
  );
});
