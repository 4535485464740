import { Language } from '@kanbu/schema/enums';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import cs from '../assets/locales/cs.json';
import en from '../assets/locales/en.json';

export const DEFAULT_NAMESPACE = 'glossary';
export const SUPPORTED_LANGUAGES = [Language.Czech, Language.English];

export interface I18nCsResources {
  glossary: typeof cs;
}

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof DEFAULT_NAMESPACE;
    resources: I18nCsResources;
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: Language.Czech,
    nonExplicitSupportedLngs: true,
    load: 'languageOnly',
    cleanCode: true,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: DEFAULT_NAMESPACE,
    supportedLngs: SUPPORTED_LANGUAGES,
    detection: {
      order: ['path', 'subdomain', 'htmlTag', 'querystring'],
    },
    resources: {
      cs: {
        glossary: cs,
      },
      en: {
        glossary: en,
      },
    },
  });

export { i18n };
