import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';
import { ChatModel, UsageRecordOperation } from '../enums';

export const tokenUsageSummarySchema = z.object({
  input: z.object({
    tokens: z.number().int().nonnegative(),
    price: z.number().nonnegative(),
  }),
  output: z.object({
    tokens: z.number().int().nonnegative(),
    price: z.number().nonnegative(),
  }),
  total: z.object({
    tokens: z.number().int().nonnegative(),
    price: z.number().nonnegative(),
  }),
});

export const streamUsageChunkSchema = z
  .object({
    model: z.nativeEnum(ChatModel),
    step: z.string().optional(),
  })
  .and(tokenUsageSummarySchema);

export const messageUsageRecordSchema = z.object({
  messageId: z.string().uuid(),
  details: z.array(streamUsageChunkSchema),
});

export const modelUsageRecordSchema = z.object({
  model: z.nativeEnum(ChatModel),
  details: z.any().optional(),
});

export type TokenUsageSummary = z.infer<typeof tokenUsageSummarySchema>;
export type StreamUsageChunk = z.infer<typeof streamUsageChunkSchema>;
export type MessageUsageRecord = z.infer<typeof messageUsageRecordSchema>;
export type ModelUsageRecord = z.infer<typeof modelUsageRecordSchema>;

// Base UsageRecord schema
export const usageRecordSchema = baseOrganizationEntitySchema.extend({
  operation: z.nativeEnum(UsageRecordOperation),
  inputTokens: z.number().int().nonnegative(),
  outputTokens: z.number().int().nonnegative(),
  totalTokens: z.number().int().nonnegative(),
  price: z.number().nonnegative(),
  messageId: z.string().uuid().optional(),
  metadata: z
    .union([messageUsageRecordSchema, modelUsageRecordSchema])
    .optional(),
});

export type UsageRecordDTO = z.infer<typeof usageRecordSchema>;
