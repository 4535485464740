import type { StateCreator } from 'zustand';

export interface FAQSlice {
  currentFAQId?: string;
  childrenCount?: number;
  isInFAQLoop: boolean;
  setFAQ: (id: string, childrenCount: number) => void;
  setIsInFAQLoop: (val: boolean) => void;
  resetFAQLoop: () => void;
}

export const createFAQSlice: StateCreator<FAQSlice> = set => ({
  isInFAQLoop: true,
  currentFAQId: undefined,
  childrenCount: undefined,
  setFAQ: (id: string, childrenCount: number) =>
    set({ currentFAQId: id, childrenCount: childrenCount }),
  setIsInFAQLoop: (val: boolean) => set({ isInFAQLoop: val }),
  resetFAQLoop: () =>
    set({
      currentFAQId: undefined,
      childrenCount: undefined,
      isInFAQLoop: true,
    }),
});
