import type defaultConfig from '../../config/default.cjs';

import { version } from '../../package.json';

const envConfig = __KANBU_CHATBOT_CONFIG__ as any as typeof defaultConfig & {
  debug: boolean;
};

export const AppSettings = {
  ...envConfig,
  debug: document.cookie.includes('debug=true') || envConfig.debug,
  version,
};
