import { memo } from 'react';

import { MessageContainer } from './MessageContainer';
import { MessageMarkdown } from './MessageMarkdown';

export type MarkdownMessageContainerProps = {
  children?: string;
};

export const MarkdownMessageContainer = memo(function MarkdownMessageContainer({
  children,
}: MarkdownMessageContainerProps) {
  return (
    <MessageContainer>
      <MessageMarkdown>{children}</MessageMarkdown>
    </MessageContainer>
  );
});
