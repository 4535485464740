import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';

// Base FAQ schema
export const faqSchema = baseOrganizationEntitySchema.extend({
  title: z.string().max(100),
  question: z.string(),
  answer: z.string(),
  active: z.boolean(),
  parent: z.string().uuid().nullish(),
  clickCount: z.number().int().nonnegative(),
});

// Create DTO schema
export const createFAQSchema = faqSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    clickCount: true,
    organization: true,
  })
  .extend({
    active: z.boolean().default(true),
  })
  .strict();

// Update DTO schema
export const updateFAQSchema = faqSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type FAQDTO = z.infer<typeof faqSchema>;
export type CreateFAQDTO = z.infer<typeof createFAQSchema>;
export type UpdateFAQDTO = z.infer<typeof updateFAQSchema>;
