import { ChatRole } from '@kanbu/schema/enums';
import { cn } from '@utima/ui';
import { memo, useContext, type ComponentPropsWithoutRef } from 'react';

import { MessageLayoutContext } from './MessageLayout';

export type MessagedHeaderProps = ComponentPropsWithoutRef<'div'> & {};

export const MessagedHeader = memo(function MessagedHeader({
  className,
  children,
  ...restProps
}: MessagedHeaderProps) {
  const { role } = useContext(MessageLayoutContext);

  return (
    <div
      className={cn(
        className,
        'block pb-1 text-sm font-bold leading-6 text-foreground',
        role === ChatRole.Assistant ? 'text-left' : 'text-right',
      )}
      {...restProps}
    >
      {children}
    </div>
  );
});
