import { cn } from '@utima/ui';
import { motion, AnimatePresence } from 'framer-motion';
import { memo, type ReactNode } from 'react';

import { useBoundStore } from '@/store/store';

import { TopBar } from '../topBar/TopBar';

export type ChatWindowProps = {
  children?: ReactNode;
};

const chatWindowVariants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
    y: '100%',
    transformOrigin: 'bottom right',
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 25,
      duration: 0.25,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    y: '100%',
    transformOrigin: 'bottom right',
    transition: {
      duration: 0.15,
    },
  },
};

export const ChatWindow = memo(function ChatWindow({
  children,
}: ChatWindowProps) {
  const opened = useBoundStore(state => state.opened);

  return (
    <AnimatePresence>
      {opened && (
        <motion.div
          aria-hidden={!opened}
          tabIndex={opened ? undefined : -1}
          className={cn(
            'fixed bottom-0 right-0 z-[10000] flex size-full flex-col overflow-hidden bg-background shadow-xl drop-shadow backdrop-blur-md sm:m-4 sm:h-[calc(100%-32px)] sm:max-h-[900px] sm:w-[420px] sm:max-w-[420px] sm:rounded-2xl',
          )}
          initial='hidden'
          animate='visible'
          exit='exit'
          variants={chatWindowVariants}
        >
          <div className='shrink-0 overflow-hidden'>
            <TopBar />
          </div>
          <div className='grow overflow-hidden'>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});
