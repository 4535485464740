import { Content, Root, Provider, Trigger } from '@radix-ui/react-tooltip';
import { memo, type ComponentPropsWithoutRef, type ReactNode } from 'react';

export interface TooltipProps
  extends Omit<ComponentPropsWithoutRef<typeof Content>, 'title'>,
    Omit<ComponentPropsWithoutRef<typeof Root>, 'children'>,
    Omit<ComponentPropsWithoutRef<typeof Provider>, 'children'> {
  title: ReactNode;
}

export const Tooltip = memo(function Tooltip({
  delayDuration = 500,
  skipDelayDuration = 200,
  disableHoverableContent,
  title,
  children,
  defaultOpen,
  open,
  onOpenChange,
  sideOffset = 4,
  ...restProps
}: TooltipProps) {
  return (
    <Provider
      delayDuration={delayDuration}
      skipDelayDuration={skipDelayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <Root open={open} onOpenChange={onOpenChange} defaultOpen={defaultOpen}>
        <Trigger asChild>{children}</Trigger>
        <Content
          sideOffset={sideOffset}
          className='z-50 overflow-hidden rounded-radius border border-popover-border bg-popover px-3 py-1.5 text-sm text-popover-fg shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2'
          {...restProps}
        >
          {title}
        </Content>
      </Root>
    </Provider>
  );
});
