import { ChatRole } from '@kanbu/schema/enums';
import { cn } from '@utima/ui';
import { memo, useContext, type ComponentPropsWithoutRef } from 'react';

import { MessageLayoutContext } from './MessageLayout';

export interface MessageContainerProps
  extends ComponentPropsWithoutRef<'div'> {}

export const MessageContainer = memo(function MessageContainer({
  children,
  className,
  ...restProps
}: MessageContainerProps) {
  const { role } = useContext(MessageLayoutContext);

  if (!children) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex rounded-2xl px-3.5 py-3',
        {
          ['rounded-br-none ml-auto bg-primary text-primary-fg']:
            role === ChatRole.User,
          ['rounded-bl-none bg-background-secondary']:
            role === ChatRole.Assistant,
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
});
