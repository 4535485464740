import { z } from 'zod';

import { baseEntitySchema } from './baseContract';
import { ChatRole } from '../enums';

export const chatMessageUsageSchema = z.object({
  input: z.number().int().nonnegative(),
  output: z.number().int().nonnegative(),
  total: z.number().int().nonnegative(),
});

export type ChatMessageUsage = z.infer<typeof chatMessageUsageSchema>;

// Base ChatMessage schema
export const chatMessageSchema = baseEntitySchema.extend({
  thread: z.string().uuid(),
  feedback: z.string().uuid().optional(),
  role: z.nativeEnum(ChatRole),
  content: z.string(),
  data: z.record(z.unknown()).optional(),
});

// Create DTO schema
export const createChatMessageSchema = chatMessageSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

// Update DTO schema
export const updateChatMessageSchema = chatMessageSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type ChatMessageDTO = z.infer<typeof chatMessageSchema>;
export type CreateChatMessageDTO = z.infer<typeof createChatMessageSchema>;
export type UpdateChatMessageDTO = z.infer<typeof updateChatMessageSchema>;
