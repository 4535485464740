import { useCallback, useEffect } from 'react';

import avatarImg from '@/assets/images/avatar.jpg';
import { AppSettings } from '@/constants/AppSettings';
import { useBoundStore } from '@/store/store';

import { Bubble } from './components/bubble/Bubble';
import { Chat } from './components/chat/Chat';
import { ChatWindow } from './components/chat/ChatWindow';
import { Route } from './components/route/Route';
import { Welcome } from './components/welcome/Welcome';

export function Widget() {
  const { open, setUnread, pushRoute } = useBoundStore(state => ({
    open: state.open,
    setUnread: state.setUnread,
    pushRoute: state.pushRoute,
  }));

  useEffect(() => {
    const storageKey = AppSettings.app.prefix + '_store';
    const storedData = sessionStorage.getItem(storageKey);
    if (storedData === null) {
      return;
    }
    const userState = JSON.parse(storedData);

    if (!userState || userState.state.user === null) {
      pushRoute('welcome');
    } else {
      pushRoute('chat');
    }
  }, [pushRoute]);

  const handleOpen = useCallback(() => {
    open();
    setUnread(0);
  }, [open, setUnread]);

  return (
    <>
      <ChatWindow>
        <Route name='welcome'>
          <Welcome />
        </Route>
        <Route name='chat'>
          <Chat />
        </Route>
      </ChatWindow>

      <Bubble src={avatarImg} onClick={handleOpen} />
    </>
  );
}
