import type { ChatUserType } from '@kanbu/schema';
import type { StateCreator } from 'zustand';

import { defaultQueryClient } from '@/services/queryClient';

import type { AppSlice } from './appSlice';
import type { RouterSlice } from './routerSlice';

export type User = ChatUserType;

export interface UserSlice {
  user: User | null;
  login: (user: User, accessToken?: string) => void;
  logout: () => void;
  accessToken?: string | null;
  setAccessToken: (token: string | null) => void;
  setUser: (user: User) => void;
}

export const createUserSlice: StateCreator<
  UserSlice & RouterSlice & AppSlice,
  [],
  [],
  UserSlice
> = (set, get) => ({
  user: null,
  setAccessToken: token =>
    set({
      accessToken: token,
    }),
  login: (user, accessToken) =>
    set({
      user,
      accessToken: accessToken,
    }),
  logout: () => {
    // Clear query cache
    defaultQueryClient.clear();

    // Clear global state
    set({ user: null, accessToken: null });

    // Redirect to welcome route
    get().pushRoute('welcome');
  },
  setUser: user => set({ user }),
});
