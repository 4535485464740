import { cn } from '@utima/ui';
import {
  memo,
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface TimeAgoProps extends ComponentPropsWithoutRef<'div'> {
  date: string;
  position?: 'left' | 'right';
}

/**
 * Calculate the time difference between the
 * provided time and the current time. It also
 * updates the time every minute.
 */
function useTimeAgo(date: string, updateInterval = 60000) {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [updateInterval]);

  const timeDifferenceInSeconds = Math.floor(
    (Number(currentTime) - Number(new Date(date).getTime())) / 1000,
  );

  if (timeDifferenceInSeconds < 60) {
    return t('timer.sendNow');
  }

  let time = '';

  if (timeDifferenceInSeconds < 3600) {
    const minutes = Math.floor(timeDifferenceInSeconds / 60);

    time = `${minutes} ${t('timer.minute', { count: minutes })}`;
  } else if (timeDifferenceInSeconds < 86400) {
    const hours = Math.floor(timeDifferenceInSeconds / 3600);

    time = `${hours} ${t('timer.hour', { count: hours })}`;
  } else {
    const days = Math.floor(timeDifferenceInSeconds / 86400);

    time = `${days} ${t('timer.day', { count: days })}`;
  }

  return `${t('timer.sendBefore', { time })}`;
}

export const TimeAgo = memo(function TimeAgo({
  date,
  position,
  className,
  ...restProps
}: TimeAgoProps) {
  const timeAgo = useTimeAgo(date, 60000);

  return (
    <div
      className={cn(
        'pt-2 text-xs text-foreground',
        {
          ['text-right']: position === 'right',
          ['text-left']: position === 'left',
        },
        className,
      )}
      {...restProps}
    >
      {timeAgo}
    </div>
  );
});
