import { ChatRole } from '@kanbu/schema/enums';
import { memo } from 'react';

import type { ChatMessageItem } from '@/hooks/chatTypes';

import { MarkdownMessageContainer } from './components/MarkdownMessageContainer';
import { MessageContent } from './components/MessageContent';
import { MessageFooter } from './components/MessageFooter';
import { MessageLayout } from './components/MessageLayout';

export type UserMessageProps = {
  data: ChatMessageItem;
};

export const UserMessage = memo(function Message({ data }: UserMessageProps) {
  const { createdAt, content } = data;

  return (
    <MessageLayout role={ChatRole.User} className='mt-3'>
      <MessageContent>
        <MarkdownMessageContainer>{content}</MarkdownMessageContainer>
      </MessageContent>
      <MessageFooter createdAt={createdAt} />
    </MessageLayout>
  );
});
