import nanomemoize from 'nano-memoize';
import type { Merge } from 'type-fest';

/**
 * This type represents default set of query keys available
 * for each entity.
 */
export type BaseKeys<T> = {
  all: () => readonly [T];
  lists: () => readonly [T, 'list'];
  details: () => readonly [T, 'detail'];
  detail: (id: string) => readonly [T, 'detail', typeof id];
  count: () => readonly [T, 'list', 'count'];
};

/**
 * Helper for creating query key factory functions
 */
export function createQueryKeys<
  T extends string,
  R extends Record<string, unknown>,
>(name: T, creator: (keys: BaseKeys<T>) => R = () => ({}) as R) {
  const base: BaseKeys<T> = {
    all: () => [name] as const,
    lists: () => [name, 'list'] as const,
    details: () => [name, 'detail'] as const,
    detail: (id: string) => [name, 'detail', id] as const,
    count: () => [name, 'list', 'count'] as const,
  };

  /**
   * Memoize resulted factories to prevent unnecessary re-renders
   * when used directly in component props
   */
  return Object.entries({
    ...base,
    ...creator(base),
  }).reduce((acc, [key, value]) => {
    acc[key as never] = nanomemoize(value as never, {
      maxAge: Number.POSITIVE_INFINITY,
    });

    return acc;
  }, {}) as Merge<typeof base, R>;
}
