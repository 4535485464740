import { z } from 'zod';

import { baseEntitySchema } from './baseContract';

// Base RefreshToken schema
export const refreshTokenSchema = baseEntitySchema.extend({
  token: z.string(),
  expiresAt: z.date(),
  userId: z.string().uuid(),
});

// Create DTO schema
export const createRefreshTokenSchema = refreshTokenSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

// Update DTO schema
export const updateRefreshTokenSchema = refreshTokenSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type RefreshTokenDTO = z.infer<typeof refreshTokenSchema>;
export type CreateRefreshTokenDTO = z.infer<typeof createRefreshTokenSchema>;
export type UpdateRefreshTokenDTO = z.infer<typeof updateRefreshTokenSchema>;
