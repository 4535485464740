import { cn } from '@utima/ui';
import { memo } from 'react';

type CharacterCounterProps = {
  max: number;
  current: number;
};

export const CharacterCounter = memo(function CharacterCounter({
  max,
  current,
}: CharacterCounterProps) {
  return (
    <span className={cn('text-xs', current >= max ? 'text-red' : 'text-gray')}>
      {current}/{max}
    </span>
  );
});
