import { useEffect, useState } from 'react';

/**
 * Throttles change of the loading state. When the value is set to true,
 * it will wait for the delay before setting the loading state to true.
 * When the value is set to false, it will set the loading state to false
 * immediately.
 */
export function useDelayedLoading(value: boolean, delay = 500) {
  const [isLoading, setLoading] = useState(value);

  useEffect(() => {
    if (value) {
      const timer = setTimeout(() => {
        setLoading(true);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }

    setLoading(false);
  }, [value, delay]);

  return isLoading;
}
