import { memo, type ReactNode } from 'react';

import type { Routes } from '@/store/slices/routerSlice';
import { useBoundStore } from '@/store/store';

export type RouteProps = {
  name: Routes;
  children: ReactNode;
};

export const Route = memo(function Route({ name, children }: RouteProps) {
  const route = useBoundStore(state => state.route);

  if (route !== name) {
    return null;
  }

  return children;
});
