import { ChatRole } from '@kanbu/schema/enums';
import { cn } from '@utima/ui';
import { memo, useContext, type ComponentPropsWithoutRef } from 'react';

import { MessageLayoutContext } from './MessageLayout';
import { UserAvatar } from './UserAvatar';

export type MessageContentProps = ComponentPropsWithoutRef<'div'> & {
  avatarSrc?: string;
};

export const MessageContent = memo(function MessageContent({
  className,
  avatarSrc,
  children,
  ...restProps
}: MessageContentProps) {
  const { role } = useContext(MessageLayoutContext);

  return (
    <div
      className={cn(
        'flex items-end gap-2 max-w-[90%]',
        {
          ['flex-row']: role === ChatRole.Assistant,
          ['flex-row-reverse']: role === ChatRole.User,
        },
        className,
      )}
      {...restProps}
    >
      {avatarSrc && (
        <div className='shrink-0'>
          <UserAvatar src={avatarSrc} />
        </div>
      )}
      <div className='flex flex-col gap-1'>{children}</div>
    </div>
  );
});
