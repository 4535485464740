import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { memo, useEffect } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

export const Welcome = memo(function Welcome() {
  const { chatId } = useChatConfig();
  const { login, pushRoute } = useBoundStore(state => ({
    login: state.login,
    pushRoute: state.pushRoute,
  }));

  /**
   * Authenticate users against API, this will create a new user
   * in the database if it does not exist. Otherwise, it will return
   * the existing user.
   */
  const { mutateAsync: authenticate } = useMutation({
    // TODO handle error
    mutationFn: async () => {
      const data = await aiCoreApi.auth.local({
        chatId,
      });

      login(data.user, data.token);

      pushRoute('chat');
    },
  });

  // Quickly authenticate user autonomously
  useEffect(() => {
    authenticate();
  }, [authenticate]);

  return (
    <div className='relative flex size-full items-center justify-center'>
      <Loader2 className='size-10 animate-spin' />
    </div>
  );
});
