import type { z } from 'zod';

import { baseEntitySchema } from './baseContract';

export const chatUserSchema = baseEntitySchema;

export const createChatUserSchema = chatUserSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .strict();

export const updateChatUserSchema = chatUserSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

export type ChatUserDTO = z.infer<typeof chatUserSchema>;
export type CreateChatUserDTO = z.infer<typeof createChatUserSchema>;
export type UpdateChatUserDTO = z.infer<typeof updateChatUserSchema>;
