import { Rating } from '@kanbu/schema/enums';
import {
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { aiCoreApi } from '@/services/aiCoreClient';

import { FeedbackButton } from './FeedbackButton';
import { FeedbackFrame } from './FeedbackFrame';
import { FeedbackTextArea } from './FeedbackTextArea';
import { Tooltip } from '../tooltip/Tooltip';

export type FeedbackProps = {
  messageId?: string;
};

export const Feedback = memo(function Feedback({ messageId }: FeedbackProps) {
  const { t } = useTranslation();

  const [rated, setRated] = useState<Rating | undefined>(undefined);
  const [customVisible, setCustomVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  const { mutateAsync } = useMutation({
    mutationFn: async ({
      message,
      rating,
    }: {
      message?: string;
      rating: Rating;
    }) => {
      if (!messageId) {
        return;
      }

      return aiCoreApi.feedback.create({
        messageId: messageId,
        text: message,
        rating: rating,
      });
    },
  });

  /**
   * Handle feedback submission and close the feedback form.
   */
  const handleFeedback = useCallback(
    async (rating: Rating, message?: string) => {
      try {
        setVisible(false);
        await mutateAsync({ message, rating });
      } catch (error) {
        console.error(error);
      }
    },
    [mutateAsync],
  );

  if (!messageId) {
    return null;
  }

  return (
    <div className='w-[85%]'>
      <div className='-mt-9 ml-6 flex flex-row justify-end gap-1'>
        <div className='flex'>
          <Tooltip
            delayDuration={0}
            sideOffset={4}
            skipDelayDuration={300}
            title={t('feedback.positive')}
          >
            <Button
              size='sm'
              variant='secondary'
              className='inline-flex items-center justify-center !rounded-full bg-foreground text-background'
              onClick={e => {
                // Don't do anything if the user has already rated
                if (rated) {
                  return e.preventDefault();
                }

                handleFeedback(Rating.Positive);
                setRated(Rating.Positive);
              }}
            >
              {rated === Rating.Positive ? (
                <IconThumbUpFilled className='size-5' />
              ) : (
                <IconThumbUp className='size-5' />
              )}
            </Button>
          </Tooltip>
        </div>
        <div className='flex'>
          <Tooltip
            delayDuration={0}
            sideOffset={4}
            skipDelayDuration={300}
            title={t('feedback.negative')}
          >
            <Button
              size='sm'
              variant='secondary'
              className='inline-flex items-center justify-center !rounded-full bg-foreground text-background'
              onClick={e => {
                // Don't do anything if the user has already rated
                if (rated) {
                  return e.preventDefault();
                }

                // Send negative feedback
                handleFeedback(Rating.Negative);

                setVisible(v => true);
                setRated(Rating.Negative);
              }}
            >
              {rated === Rating.Negative ? (
                <IconThumbDownFilled className='size-5' />
              ) : (
                <IconThumbDown className='size-5' />
              )}
            </Button>
          </Tooltip>
        </div>
      </div>
      <AnimatePresence>
        {visible && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
          >
            <div className='mt-4 flex flex-col items-end gap-2'>
              <div className='relative flex w-full rounded-lg bg-foreground px-3.5 py-2 text-white sm:w-fit'>
                <div className='absolute left-3/4 top-0 ml-[21px] size-0 -translate-x-6 -translate-y-full rotate-180 border-x-[6px] border-t-8 border-foreground border-x-transparent sm:left-[86px] sm:ml-4' />
                <p>{t('feedback.negative')}</p>
              </div>

              <FeedbackFrame onClose={() => setVisible(false)}>
                <p className='mb-4'>{t('feedback.tellUsMore')}</p>
                <div className='flex flex-wrap justify-stretch gap-2'>
                  <FeedbackButton
                    onClick={() => {
                      handleFeedback(Rating.Negative, t('feedback.irrelevant'));
                    }}
                  >
                    {t('feedback.irrelevant')}
                  </FeedbackButton>
                  <FeedbackButton
                    onClick={() =>
                      handleFeedback(Rating.Negative, t('feedback.missing'))
                    }
                  >
                    {t('feedback.missing')}
                  </FeedbackButton>
                  <FeedbackButton onClick={() => setCustomVisible(true)}>
                    {t('feedback.other')}
                  </FeedbackButton>
                </div>
              </FeedbackFrame>
              <AnimatePresence>
                {customVisible && (
                  <motion.div
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 15 }}
                    className='w-full'
                  >
                    <FeedbackFrame
                      className='animate-in fade-in'
                      title={t('feedback.provideFeedback')}
                    >
                      <FeedbackTextArea
                        placeholder={t('feedback.placeholder')}
                        initialMinHeight='82px'
                        onSubmit={message =>
                          handleFeedback(Rating.Negative, message)
                        }
                      />
                    </FeedbackFrame>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});
