import { createQueryKeys } from '@kanbu/shared';
import { QueryClient } from '@tanstack/react-query';

export const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const chatKeys = createQueryKeys('chat', baseKeys => ({
  config: (chatId: string) => [...baseKeys.all(), 'config', chatId],
}));

export const faqKeys = createQueryKeys('faqs', baseKeys => ({
  chatLists: (chatId: string) => [...baseKeys.lists(), 'chat', chatId],
}));

export const threadKeys = createQueryKeys('threads', baseKeys => ({
  chatLists: (chatId: string) => [...baseKeys.lists(), 'chat', chatId],
}));
