import { ChatRole } from '@kanbu/schema/enums';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HTTPError } from '@toss/ky';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
  type ChangeEvent,
  type FormEvent,
} from 'react';
import { v7 as uuid } from 'uuid';

import { processChatStream } from '@/lib/streamingUtils';
import { aiCoreApi } from '@/services/aiCoreClient';
import { chatKeys } from '@/services/queryClient';

import type {
  InsertOptions,
  ChatMessageItem,
  UseChatParams,
  UseChatReturn,
} from './chatTypes';

const INITIAL_MESSAGES: ChatMessageItem[] = [];

/**
 * Helper function to create a new message object.
 */
export function createMessage(data: {
  message: string | ChatMessageItem;
  role: ChatRole;
}): ChatMessageItem {
  return typeof data.message === 'string'
    ? {
        role: data.role,
        content: data.message,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: uuid(),
      }
    : data.message;
}

/**
 * Custom hook to handle chat logic, sending and receiving messages,
 * and managing chat history.
 */
export function useChat({
  initialInput = '',
  initialMessages = INITIAL_MESSAGES,
  threadId,
  chatId,
  model,
  embeddingsVersion,
  onMessage,
  onError,
}: UseChatParams): UseChatReturn {
  const [_, startTransition] = useTransition();
  const [input, setInput] = useState(initialInput);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isInitialized, setIsInitialized] = useState(!!threadId);
  const abortController = useRef<null | AbortController>(null);

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [...chatKeys.detail(chatId), threadId],
    [chatId, threadId],
  );

  /**
   * Fetch message history, we also use this to store the chat
   * history in the state. This automatically handles cache invalidation
   * when thread is changed.
   */
  const { data: messages, isFetching: isInitializingMessages } = useQuery<
    ChatMessageItem[]
  >({
    queryKey: queryKey,
    queryFn: async () => {
      try {
        const data = await aiCoreApi.threads.findOne(
          {
            chatId,
            id: threadId!,
          },
          {
            /**
             * We don't want to throw an error if the thread is not found,
             * since it can be created automatically on the fly.
             */
            throwHttpErrors: false,
            retry: 0,
          },
        );

        /**
         * Since we use uuid v7 which is time based, we can sort the messages
         * by id to get the correct order.
         */
        return data.messages.length > 0
          ? data.messages.sort((a, b) => a.id.localeCompare(b.id))
          : initialMessages;
      } catch {
        return initialMessages;
      }
    },
    placeholderData: INITIAL_MESSAGES,
    // We want to keep the messages in the cache forever
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!threadId,
  });

  /**
   * Abort the current request.
   */
  const abort = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
  }, []);

  /**
   * Helper for calling completion API with stream option.
   */
  const callCompletionApi = useCallback(
    async (message: ChatMessageItem | string) => {
      const content = typeof message === 'string' ? message : message.content;

      // Bail if no content
      if (!content) {
        return;
      }

      // Abort previous request
      if (
        abortController.current?.signal &&
        !abortController.current.signal.aborted
      ) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      // Get the completion API endpoint stream
      const response = await aiCoreApi.chat.completion(
        {
          chatId,
          threadId: threadId!,
          message: content,
          options: {
            model,
            embeddingsVersion,
          },
        },
        {
          raw: true,
          timeout: undefined,
          signal: abortController.current?.signal,
        },
      );

      return response.body?.getReader();
    },
    [chatId, abortController, threadId, model, embeddingsVersion],
  );

  useEffect(() => {
    setIsInitialized(!!threadId);
  }, [threadId]);

  /**
   * Insert message into the chat state without sending it to the API.
   */
  const insert = useCallback(
    (
      message: ChatMessageItem | string,
      role: ChatRole = ChatRole.User,
      options?: InsertOptions,
    ) => {
      const { timeout = 0 } = options || {};

      (async () => {
        if (timeout > 0) {
          setIsLoading(true);
          await new Promise(resolve => setTimeout(resolve, timeout));
        }

        queryClient.setQueryData(queryKey, (prev: ChatMessageItem[]) => [
          ...prev,
          createMessage({
            message,
            role,
          }),
        ]);

        setIsLoading(false);
      })();
    },
    [queryClient, queryKey],
  );

  /**
   * Create new message, add it to the state and send it to the API.
   */
  const append = useCallback(
    async (message: ChatMessageItem | string) => {
      if (!threadId) {
        return console.error('Thread ID is required to send a message.');
      }

      startTransition(() => {
        insert(message);
        setIsLoading(true);
      });

      try {
        /**
         * Process chat stream, this function handles the streaming
         * response from the API and updates the chat state with new messages.
         */
        const resultMessage = await processChatStream({
          abortController: () => abortController.current,
          onUpdate: (newMessage: ChatMessageItem) => {
            /**
             * We need to update the message in the state, if it already exists.
             * Otherwise we just append the new message.
             */
            queryClient.setQueryData(queryKey, (prev: ChatMessageItem[]) => {
              // Disable loading state as soon as we get the first chunk
              startTransition(() => {
                setIsLoading(false);
                setIsStreaming(true);
              });

              const initialMessageIndex = prev.findIndex(
                m => m.id === newMessage.id,
              );

              if (initialMessageIndex !== -1) {
                return [
                  ...prev.slice(0, initialMessageIndex),
                  { ...newMessage },
                  ...prev.slice(initialMessageIndex + 1),
                ];
              }

              return [...prev, newMessage];
            });
          },
          reader: (await callCompletionApi(
            message,
          )) as ReadableStreamDefaultReader<Uint8Array>,
        });

        if (resultMessage) {
          onMessage?.(resultMessage, insert);
        }
      } catch (error) {
        let parsedError = error;

        // Parse ky error response
        if (error instanceof HTTPError) {
          parsedError = await error.response.json();
        }

        setError(parsedError);
        onError?.(parsedError, insert);
      } finally {
        startTransition(() => {
          setIsLoading(false);
          setIsStreaming(false);
        });
      }
    },
    [
      threadId,
      insert,
      callCompletionApi,
      queryClient,
      queryKey,
      onMessage,
      onError,
    ],
  );

  /**
   * Input/textarea on change handler, automatically updates input value.
   */
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value);
    },
    [],
  );

  /**
   * Handle form submission. Sends message to the API and resets input field.
   */
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      startTransition(() => {
        append(input);
        setInput('');
      });
    },
    [append, input],
  );

  return {
    error,
    isLoading,
    isStreaming,
    isInitialized: !isInitializingMessages && isInitialized,
    messages: messages ?? INITIAL_MESSAGES,
    input,
    setInput,
    handleSubmit,
    handleInputChange,
    abort,
    append,
    insert,
  };
}
