import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';
import { Role } from '../enums';

// Base User schema
export const userSchema = baseOrganizationEntitySchema.extend({
  email: z.string().email().max(255),
  password: z.string().max(255).nullish(),
  firstName: z.string().max(50),
  lastName: z.string().max(50),
  photo: z.string().optional(),
  verified: z.boolean(),
  role: z.nativeEnum(Role),
});

// Create DTO schema
export const createUserSchema = userSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    verified: true,
    organization: true,
  })
  .extend({
    verified: z.boolean().optional(),
  })
  .strict();

// Update DTO schema
export const updateUserSchema = userSchema
  .partial()
  .required({
    id: true,
  })
  .omit({
    organization: true,
    // We don't want to allow changing email of the user
    email: true,
  })
  .extend({
    password: z.string().max(255).nullish(),
    oldPassword: z.string().max(255).nullish(),
  })
  .strict();

export type UserDTO = z.infer<typeof userSchema>;
export type CreateUserDTO = z.infer<typeof createUserSchema>;
export type UpdateUserDTO = z.infer<typeof updateUserSchema>;
