
      import { App } from '/src/App.tsx';
      import { Sandbox } from '/src/sandbox/Sandbox.tsx';
      import { createRoot } from 'react-dom/client';
      import { createElement } from 'react';

      const rootSandboxElement = document.getElementById('root-sandbox');
      const rootSandbox = createRoot(rootSandboxElement);

      const renderApp = ({ chatId }) => {
        const chatbot = document.createElement('kanbu-chatbot');
        chatbot.setAttribute('chat', chatId);
        document.body.append(chatbot);
      };

      if (rootSandboxElement) {
        rootSandbox.render(createElement(Sandbox, { renderApp }));
      }
    