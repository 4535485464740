import { z } from 'zod';

import { baseEntitySchema } from './baseContract';
import { chatMessageSchema } from './chatMessageContract';

// Base Thread schema
export const threadSchema = baseEntitySchema.extend({
  chatUser: z.string().uuid(),
  title: z.string().max(100),
  chat: z.string().uuid(),
  messages: z.array(chatMessageSchema).optional(),
});

// Create DTO
export const createThreadSchema = threadSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    messages: true,
  })
  .strict();

// Update DTO
export const updateThreadSchema = threadSchema
  .partial()
  .required({
    id: true,
  })
  .strict();

// Types
export type ThreadDTO = z.infer<typeof threadSchema>;
export type CreateThreadDTO = z.infer<typeof createThreadSchema>;
export type UpdateThreadDTO = z.infer<typeof updateThreadSchema>;
