import DOMPurify from 'dompurify';
import { marked } from 'marked';

export const markdownRenderer = new marked.Renderer();
const linkRenderer = markdownRenderer.link;

/**
 * Override the link renderer to add target="_blank" rel="nofollow" to all links.
 */
markdownRenderer.link = ({ href, title, tokens }) => {
  // @ts-expect-error invalid types
  const html = linkRenderer.call(markdownRenderer, { href, title, tokens });

  return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
};

/**
 * Renders markdown to HTML, sanitizing the input first.
 */
export function renderMarkdown(markdown: string | undefined | null): string {
  if (!markdown) {
    return '';
  }

  const sanitized = DOMPurify.sanitize(markdown);

  return marked(sanitized, { renderer: markdownRenderer, async: false });
}
