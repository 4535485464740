import type { StateCreator } from 'zustand';

export interface ThreadsSlice {
  threadId: string | null;
  setThreadId: (threadId: string) => void;
}

export const createThreadsSlice: StateCreator<ThreadsSlice> = set => ({
  threadId: null,
  setThreadId: threadId => set({ threadId }),
});
