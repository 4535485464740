import { ChatRole } from '@kanbu/schema/enums';
import { cn } from '@utima/ui';
import { memo, useContext, type ComponentPropsWithoutRef } from 'react';

import { TimeAgo } from '@/components/timeAgo/TimeAgo';

import { MessageLayoutContext } from './MessageLayout';

export type MessageFooterProps = ComponentPropsWithoutRef<'div'> & {
  createdAt?: string;
};

export const MessageFooter = memo(function MessageFooter({
  className,
  createdAt,
  children,
  ...restProps
}: MessageFooterProps) {
  const { role } = useContext(MessageLayoutContext);

  return (
    <div className={cn('w-full', className)} {...restProps}>
      {createdAt && (
        <TimeAgo
          position={role === ChatRole.Assistant ? 'left' : 'right'}
          date={createdAt}
        />
      )}
      {children}
    </div>
  );
});
